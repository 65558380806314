<template>
  <!-- wallets-futures/合约 -->
  <div class="order-detail-wrap">
    <van-nav-bar :border="false" z-index="99" :fixed="true" left-text="" @click-left="back" left-arrow>
      <div slot="left">
        <img src="@/assets/images/system/left-arrow.svg"/>
      </div>
      <div slot="title">
       {{ $t('history.orderDetail') }}
      </div>
    </van-nav-bar>
    <PullRefresh @refreshFn="refreshFn">
      <div class="history-tab-wrap">
        <div class="card-box padding15 first-card">
          <p class="font12 main-text-3 font-weight400">
            {{ $t('history.symbol') }}
          </p>
          <div class="ub ub-h-e">
            <p class="font18 main-text-1 font-weight500">
              {{orderDetail.symbol}}
            </p>
            <p class="font14 main-text-3 font-weight400 margin-left4">
            {{ $t('history.perpetual') }}
            </p>
          </div>
          <div class="up-color font12">
            {{ $t('history.filled') }}(100%)
          </div>
        </div>
        <div class="card-box padding15 margin-top8">
          <div class="ub ub-btw ">
            <div class="font12 main-text-3 font-weight400"> {{ $t('history.orderNo') }}</div>
            <div class="ub ub-h-c">
              <div class="main-text-1 font14 font-weight500">{{orderDetail.id}}</div>
              <div class="ub ub-cen">
                <CopyWrap :text="'123456'">
                  <img src="@/assets/images/profile/copy.svg"/>
                </CopyWrap>
              </div>
            </div>
          </div>
          <div class="ub ub-btw margin-top8">
            <div class="font12 main-text-3 font-weight400">{{ $t('history.type') }}</div>
            <div>
              <div v-if="orderDetail.side === 'BUY'" class="up-color">
                {{orderDetail.pos_side === 'LONG'?$t('history.openLong'):$t('history.closeShort')}}
              </div>
              <div v-if="orderDetail.side === 'SELL'" class="down-color">
                {{orderDetail.pos_side === 'LONG'?$t('history.closeLong'):$t('history.openShort')}}
              </div>
            </div>
          </div>
          <div class="ub ub-btw margin-top8">
            <!-- <span v-if="orderDetail.symbol">({{ orderDetail.symbol.split('U')[0] }})</span> -->
            <div class="font12 main-text-3 font-weight400">{{ $t('history.filledAmount') }} (USDT)</div>
            <div class="ub ub-h-c">
              <span>{{orderDetail.amount}}</span> / <span  class="font12 main-text-3 font-weight400">{{orderDetail.amount}}</span>
            </div>
          </div>
          <div class="ub ub-btw margin-top8">
            <div class="font12 main-text-3 font-weight400">{{ $t('history.AvgPrice') }} </div>
            <div class="ub ub-h-c">
              <span>{{orderDetail.avg_price}}</span> / <span  class="font12 main-text-3 font-weight400">--</span>
            </div>
          </div>
          <!-- <div class="ub ub-btw margin-top8">
            <div class="font12 main-text-3 font-weight400">{{ $t('history.reduceOnly') }}</div>
            <div class="ub ub-h-c">
              True
            </div>
          </div> -->
          <div class="border-bottom1 margin-top8"></div>
          <div class="ub ub-btw margin-top8">
            <div class="font12 main-text-3 font-weight400">{{$t('history.fee')}}</div>
            <div class="ub ub-h-c">
            {{orderDetail.fee}} USDT
            </div>
          </div>
          <div class="ub ub-btw margin-top8">
            <div class="font12 main-text-3 font-weight400">{{$t('history.realizedPNL')}}</div>
            <div class="ub ub-h-c">
            {{orderDetail.profit_amount}} USDT
            </div>
          </div>
          <div class="ub ub-btw margin-top8">
            <div class="font12 main-text-3 font-weight400">{{$t('history.timeCreated')}}</div>
            <div class="ub ub-h-c">
              {{orderDetail.trade_create_time}}
            </div>
          </div>
          <div class="ub ub-btw margin-top8">
            <div class="font12 main-text-3 font-weight400">{{$t('history.timeUpdated')}}</div>
            <div class="ub ub-h-c">
              {{orderDetail.trade_end_time}}
            </div>
          </div>
        </div>
        <div class="card-box padding15 margin-top8" v-if="orderDetail.trade_order_detail_list">
          <div class="font14">{{$t('history.tradeDetails')}}</div>
          <div class="margin-bottom16 padding-bottom16" v-for="(item, index) in orderDetail.trade_order_detail_list" :key="index" style="border-bottom: 1px solid #F5F5F5;">
            <div class="ub ub-btw margin-top8">
              <div class="font12 main-text-3 font-weight400">{{$t('history.date')}}</div>
              <div class="ub ub-h-c">
                {{item.trade_create_time}}
              </div>
            </div>
            <div class="ub ub-btw margin-top8">
              <div class="font12 main-text-3 font-weight400">{{$t('history.quantity')}}</div>
              <div class="ub ub-h-c">
                {{item.quantity}}<span v-if="orderDetail.symbol">({{ orderDetail.symbol.split('U')[0] }})</span>
              </div>
            </div>
            <div class="ub ub-btw margin-top8">
              <div class="font12 main-text-3 font-weight400">{{$t('history.price')}}</div>
              <div class="ub ub-h-c">
                {{item.price}} USDT
              </div>
            </div>
            <div class="ub ub-btw margin-top8">
              <div class="font12 main-text-3 font-weight400">{{$t('history.realizedPNL')}}</div>
              <div class="ub ub-h-c">
                {{item.profit_amount}} USDT
              </div>
            </div>
            <div class="ub ub-btw margin-top8">
              <div class="font12 main-text-3 font-weight400">{{$t('history.fee')}}</div>
              <div class="ub ub-h-c">
                {{item.fee}} USDT
              </div>
            </div>
            <div class="ub ub-btw margin-top8">
              <div class="font12 main-text-3 font-weight400">{{$t('history.role')}}</div>
              <div class="ub ub-h-c">
                {{item.role}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </PullRefresh>
  </div>
</template>

<script>
import { orderDetail } from '@/api/trade.js'
import CopyWrap from '@/components/copy/index.vue'
import PullRefresh from '@/components/pullRefresh/index.vue'

export default {
  name: 'OrderDetail',
  components: { CopyWrap, PullRefresh },
  computed: {
    ID () {
      return this.$route.params.id || ''
    }
  },
  props: {
  },
  data () {
    return {
      active: 0,
      orderDetail: {}
    }
  },
  mounted () {
    this.orderDetailFn()
  },
  methods: {
    refreshFn () {
      this.orderDetailFn()
    },
    orderDetailFn () {
      const orderId = this.ID
      orderDetail({
        order_id: orderId
      }).then(res => {
        this.orderDetail = res.data
        console.log(res, 'orderDetailFn------')
        this.$toast.clear()
      })
    },
    back () {
      this.$router.back()
    }
  }
}
</script>
<style lang="less">
.order-detail-wrap{
  padding-top: 60px;
  box-sizing: border-box;
  background-color: #F5F5F5;
  .history-tab-wrap{
    background-color: #F5F5F5;
    .first-card{
      padding-top: 40px;
    }
    .card-box{
      box-sizing: border-box;
      background-color: #fff;
    }
    .up-color{
      color: var(--success-color);
    }
    .down-color{
      color: #F5465C;
    }
    .border-bottom1{
      border-bottom: 1px solid #ECEAEF;
    }
    .up-bg-color{
      padding: 0 4px;
      box-sizing: border-box;
      border-radius: 4px;
      background-color: rgba(45, 189, 133, 0.12);
      // background-color: rgba(0, 255, 0, 0.1);
    }
  }
}
</style>
